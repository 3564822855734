/* istanbul ignore file -- @preserve */
import { createApp } from 'vue';

import { router } from '@/router/router';

import App from '@/App.vue';
import { initializeVeeValidate } from '@/validation/initializeVeeValidate';

initializeVeeValidate();

createApp(App).use(router).mount('#app');
